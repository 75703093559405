import React, { useState } from 'react'
import Icon from 'components/elements/Icon'
import { useRouter } from 'next/router'
import { useCookies } from 'react-cookie'

import { DeckCardInfoType } from 'types/search'
import { DEFAULT_DECK_CARD_IMAGE, HUMAN_READABLE_FORMAT } from 'types/deck'
import { PATREON_TIERS, ROLES } from 'types/user'

import BookmarkService from 'services/bookmark.service'
import DeckService from 'services/deck.service'
import ToastService from 'services/toast.service'

import Link from 'components/elements/Link'
import ColorBar from 'components/deckPage/charts/ColorBar'
import ArchidektDropdown from 'components/elements/ArchidektDropdown'
import FadeInOut from 'components/transitions/FadeInOut'
import AvatarCircle from 'components/user/AvatarCircle'
import ConfirmDeleteModal from 'components/elements/ConfirmDeleteModal'

import { timeSince } from 'utils/time.utils'
import { simplifyNumber } from 'utils/formatting.utils'
import { tagNameToUrl } from 'utils/deckTagAliases'
import { encodeUsernameRoute } from 'utils/encoding'
import { generateDeckUrl } from 'utils/deckSeo'

import styles from './deckLink.module.scss'

type Props = {
  deck?: DeckCardInfoType
  loading?: boolean
  style?: React.CSSProperties
  index?: number
  displayOnly?: boolean
  noTags?: boolean
}

export const gridClassUnlocked = styles.deckGridUnlocked
export const gridClass = styles.deckGrid

const DeckLink = ({ deck, loading, index, style, displayOnly, noTags }: Props) => {
  const router = useRouter()

  const [bookmarked, setBookmarked] = useState(deck?.bookmarked ?? false)
  const [deleteVisible, setDeleteVisible] = useState(false)
  const [deleted, setDeleted] = useState(false)

  const [{ tbId: userId }] = useCookies(['tbId'])

  const href = generateDeckUrl(deck?.id || 0, deck?.name)

  const userHref = `/u/${encodeUsernameRoute(deck?.owner.username || '')}`
  const format = deck ? HUMAN_READABLE_FORMAT[deck.deckFormat] : ''

  const handleSloppyClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target !== e.currentTarget) return

    router.push(href)
  }

  const handleToggleBookmark = () => {
    if (!deck) return

    if (bookmarked)
      return BookmarkService.remove(deck.id)
        .then(() => ToastService.create('Bookmark removed', 'Bookmark service', 'success'))
        .then(() => setBookmarked(false))
        .catch(() => ToastService.create('Unable to delete bookmark', 'Bookmark service'))

    return BookmarkService.create(deck.id)
      .then(() => ToastService.create('Deck bookmarked', 'Bookmark service', 'success'))
      .then(() => setBookmarked(true))
      .catch(() => ToastService.create('Unable to create bookmark', 'Bookmark service'))
  }

  const handleDeleteDeck = () => {
    if (!deck?.id) return Promise.reject()

    return DeckService.deleteDeck(deck.id, true)
      .then(() => {
        ToastService.create(`${deck.name} deleted`, 'Deck service', 'success')

        setDeleted(true)
      })
      .catch(() => ToastService.create('Unable to delete deck', 'Deck service'))
      .finally(() => {
        setDeleteVisible(false)
      })
  }

  const isModerator = (deck?.owner.roles || []).includes(ROLES.MOD)
  const isCommon = !isModerator && deck?.owner.pledgeLevel === PATREON_TIERS.COMMON
  const isUncommon = !isModerator && deck?.owner.pledgeLevel === PATREON_TIERS.UNCOMMON
  const isRare = !isModerator && deck?.owner.pledgeLevel === PATREON_TIERS.RARE
  const isMythic = !isModerator && deck?.owner.pledgeLevel === PATREON_TIERS.MYTHIC
  const isCHWriter = (deck?.owner.roles || []).includes(ROLES.CH_WRITER)
  const isEDHRECWriter = (deck?.owner.roles || []).includes(ROLES.EDHREC_WRITER)
  const isSpellbookWriter = (deck?.owner.roles || []).includes(ROLES.COMMANDER_SPELLBOOK_WRITER)
  const isCommandZoneStaff = (deck?.owner.roles || []).includes(ROLES.COMMAND_ZONE_STAFF)
  const isContestWinner = (deck?.owner.roles || []).includes(ROLES.CONTEST_WINNER)
  const isCardsphereWriter = (deck?.owner.roles || []).includes(ROLES.CARDSPHERE_WRITER)

  if (deleted) return null

  return (
    <>
      <div
        style={style}
        className={`${styles.container} ${loading ? styles.loading : ''} ${displayOnly ? styles.disableCursor : ''}`}
        onClick={handleSloppyClick}>
        <Link href={href} className={styles.thumbnail} tabIndex={-1}>
          <FadeInOut show={!loading}>
            <div className={`${styles.previewImage}`}>
              <img
                alt={`${deck?.name} preview`}
                src={deck?.customFeatured || deck?.featured || DEFAULT_DECK_CARD_IMAGE}
              />
            </div>

            {/* <div className={styles.topGradientMask} /> */}
            <div className={styles.bottomGradientMask} />

            <div className={styles.views}>
              {simplifyNumber(deck?.viewCount || 0)} views • {timeSince(deck?.updatedAt || '')} ago
            </div>

            <ColorBar includeManaSymbols colors={deck?.colors || {}} className={styles.bar} />
          </FadeInOut>
        </Link>

        <FadeInOut className={styles.infoContainer} show={!loading}>
          <Link href={userHref} className={styles.avatar} tabIndex={-1}>
            <AvatarCircle username={deck?.owner.username} src={deck?.owner.avatar || ''} />
          </Link>
          <div className={styles.info}>
            <span className={styles.header}>
              <Link href={href} title={deck?.name}>
                {deck?.private && <Icon name="lock" />}
                {deck?.unlisted && <Icon name="eye slash" />}
                {deck?.name}
              </Link>
            </span>

            <span className={styles.subInfo}>
              <div>{format}</div>

              <div className={styles.subSubInfo}>
                <Link href={userHref}>
                  {deck?.owner.username}
                  {isModerator && <img src="/images/bordered-team.svg" title="Archidekt Team" alt="archidekt team" />}
                  {isCommon && <img src="/images/bordered-common.svg" title="Common patron" alt="common patron" />}
                  {isUncommon && (
                    <img src="/images/bordered-uncommon.svg" title="Uncommon patron" alt="common patron" />
                  )}
                  {isRare && <img src="/images/bordered-rare.svg" title="Rare patron" alt="common patron" />}
                  {isMythic && <img src="/images/bordered-mythic.svg" title="Mythic patron" alt="common patron" />}
                  {isCHWriter && (
                    <img
                      className={styles.invertable}
                      src="/images/ch.svg"
                      title="Commander's Herald Writer"
                      alt="commanders's herald writer"
                    />
                  )}
                  {isEDHRECWriter && (
                    <img
                      className={styles.invertable}
                      src="/images/edhrec.svg"
                      title="EDHREC Writer"
                      alt="edhrec writer"
                    />
                  )}
                  {isSpellbookWriter && (
                    <img src="/images/spellbook.svg" title="Commander Spellbook Staff" alt="spellbook staff" />
                  )}
                  {isCardsphereWriter && (
                    <img src="/images/cardsphere.svg" title="Cardsphere Writer" alt="cardsphere writer" />
                  )}
                  {isCommandZoneStaff && (
                    <img
                      className={styles.invertable}
                      src="/images/commandzone.png"
                      title="The Command Zone Staff"
                      alt="command zone staff"
                    />
                  )}
                  {isContestWinner && <Icon className={styles.semanticRoleIcon} name="trophy" title="Contest Winner" />}
                </Link>
                {deck && <div></div>}
              </div>
            </span>
          </div>

          <ArchidektDropdown
            disabled={!deck}
            defaultTriggerClassName={styles.optionsTrigger}
            menuClassName={styles.options}
            options={[
              { icon: 'exchange', to: `/partialCompare?one=d_${deck?.id}`, label: 'Compare deck' },
              // { icon: 'copy', onClick: handleCopyDeck, label: 'Copy deck' },
              // { icon: 'flask', onClick: () => null, label: 'Open in sandbox' },
              {
                hidden: !Number(userId),
                label: 'Bookmark deck',
                icon: bookmarked ? 'bookmark' : 'bookmark outline',
                onClick: handleToggleBookmark,
                className: bookmarked ? styles.bookmarked : '',
              },
              { icon: 'play circle outline', to: `/playtester-v2/${deck?.id}`, label: 'Playtest deck' },
              {
                icon: 'trash alternate outline',
                onClick: () => setDeleteVisible(true),
                label: 'Delete deck',
                hidden: deck?.owner.id !== Number(userId),
              },
              {
                type: 'extras',
                label: 'Deck tags',
                id: 'tag-links',
                icon: 'tags',
                options: (deck?.tags || []).map(tag => ({
                  label: `${tag.name}`,
                  to: `/tags/${tagNameToUrl(tag.name || '')}`,
                })),
              },
            ]}
          />
        </FadeInOut>

        {!noTags && (
          <FadeInOut show={!loading}>
            {!!deck?.tags.length ? (
              <div className={styles.tags} title={deck.tags.map(t => t.name).join(', ')}>
                <span>
                  {!!deck?.tags.length && <Icon name="tags" />}
                  {deck?.tags.map((tag, index) => {
                    return (
                      <Link href={`/tags/${tagNameToUrl(tag.name || '')}`} key={tag.id}>
                        {tag.name}
                        {index !== deck.tags.length - 1 ? ', ' : ''}
                      </Link>
                    )
                  })}
                </span>
              </div>
            ) : (
              <div className={styles.noTags}>No deck tags</div>
            )}
          </FadeInOut>
        )}
      </div>

      <ConfirmDeleteModal
        open={deleteVisible}
        onClose={() => setDeleteVisible(false)}
        onDelete={handleDeleteDeck}
        header={`Delete ${deck?.name}`}
        message="Are you sure you want to delete this deck? This action is irreversible."
        buttonText="Delete deck"
        delayEnabledTime={3000}
      />
    </>
  )
}

export default DeckLink
